<template>
  <div class="page page-createapp">
    <div class="page-content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ name: 'myapp' }">我的应用</el-breadcrumb-item>
        <el-breadcrumb-item>创建新应用</el-breadcrumb-item>
      </el-breadcrumb>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px">
        <el-form-item label="应用名称" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请设定一个应用名称，少于30个字符"></el-input>
        </el-form-item>
        <el-form-item label="应用功能描述" prop="description">
          <el-input v-model="ruleForm.description" :maxlength="1000" placeholder="请简述使用场景、应用特点等信息，不超过1000个字符"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="submit-btn" type="primary" @click="submitForm">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import publicUrl from '../../assets/js/interface'

export default {
  name: 'page-createapp',
  data () {
    return {
      loadingCount: 0,
      ruleForm: {
        name: '',
        description: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入应用名称', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入应用功能描述', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submitForm () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loadingCount++
          this.$ajaxJson.post(publicUrl.appAdd, {
            ...this.ruleForm
          }).then(res => {
            if (res.data.code === 0) {
              this.$message.success(res.data.msg)
              this.$router.push({name: 'myapp'})
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(err => {
            this.$ajaxError(err)
          }).finally(() => {
            this.loadingCount--
          })
        } else {
          return false
        }
      })
    },
    resetForm () {
      this.$refs.ruleForm.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
.page-createapp {
  padding-top: 40px;
  .page-content {
    width: 900px;
    margin: 0 auto;
    .el-breadcrumb {
      margin-bottom: 20px;
    }
  }
  .submit-btn {
    width: 100%;
  }
}
</style>
